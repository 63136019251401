export default function Guest({ children }) {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen p-6 bg-eis-off-white">

            <div className="w-full bg-white border-white border-5 max-w-[883px] rounded-xl">
                {children}
            </div>
        </div>
    );
}
